/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import { IntlContextConsumer } from 'gatsby-plugin-intl'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import ogImg from '../images/og-image.png'

function SEO({ description, meta, lang, keywords, title, nofollow, bodyAttributes, image, imageAlt }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const thisTitle = title || site.siteMetadata.title
  const ogImage = image ? site.siteMetadata.siteUrl + image : site.siteMetadata.siteUrl + ogImg
  const ogImageAlt = imageAlt || site.siteMetadata.title

  let metaArray = [
    {
      name: 'description',
      content: metaDescription
    },
    {
      property: 'og:title',
      content: title
    },
    {
      property: 'og:description',
      content: metaDescription
    },
    {
      property: 'og:type',
      content: 'website'
    },
    {
      property: 'og:image',
      content: ogImage
    },
    {
      property: 'og:image:alt',
      content: ogImageAlt
    },
    {
      property: 'og:image:width',
      content: '1200'
    },
    {
      property: 'og:image:height',
      content: '630'
    },
    {
      property: 'og:site_name',
      content: 'freshcells'
    },
    {
      name: 'twitter:card',
      content: 'summary_large_image'
    },
    {
      name: 'twitter:creator',
      content: site.siteMetadata.author
    },
    {
      name: 'twitter:title',
      content: title
    },
    {
      name: 'twitter:description',
      content: metaDescription
    },
    {
      name: 'twitter:image',
      content: ogImage
    }
  ]
  if (nofollow) {
    metaArray.push({
      name: 'robots',
      content: 'noindex, nofollow'
    })
  }
  // const lang = this.intl.locale
  return (
    <IntlContextConsumer>
      {({ languages, originalPath }) => (
        <Helmet
          htmlAttributes={{
            lang
          }}
          bodyAttributes={bodyAttributes}
          title={thisTitle}
          titleTemplate={'%s'}
          meta={metaArray
            .concat(
              keywords.length > 0
                ? {
                    name: 'keywords',
                    content: keywords.join(', ')
                  }
                : []
            )
            .concat(meta)}
        >
          <script type="application/ld+json">
            {`{
              "@context": "https://schema.org",
              "@type": "WebSite",
              "name": "freshcells",
              "alternateName" : "freshcells",
              "url" : "https://www.freshcells.de"
            }`}
          </script>
          <link
            rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/outdated-browser/1.1.5/outdatedbrowser.min.css"
            media="print"
            onLoad="this.media='all'"
          />
          {languages.map(language => (
            <link
              key={language}
              rel="alternate"
              href={site.siteMetadata.siteUrl + '/' + language + originalPath}
              hrefLang={language}
            />
          ))}
          <link rel="alternate" href={site.siteMetadata.siteUrl + originalPath} hrefLang="x-default" />
        </Helmet>
      )}
    </IntlContextConsumer>
  )
}

SEO.defaultProps = {
  lang: 'en',
  meta: [],
  keywords: [],
  description: ''
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  nofollow: PropTypes.bool,
  meta: PropTypes.arrayOf(PropTypes.object),
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
  bodyAttributes: PropTypes.object,
  image: PropTypes.node,
  imageAlt: PropTypes.string
}

export default SEO
